import React from 'react';
import routes from "../routes";
import * as api from "../utils/api";
import {logEvent, userEvents} from "../utils/log";
import AppContext from "../contexts/AppContext";
import i18n from "../i18n";
import HomeButton from "../components/HomeButton";
import Modal from "../components/Modal";
import {copyTextWithInvisibleElement} from "../utils/text";
import * as webviewUtils from "../utils/webview";
import FileChooseButton from "../components/FileChooseButton";

const COLLAGE_STATUS_FAILED = -1;
const COLLAGE_STATUS_PROCESSED = 1;

const FETCH_INTERVAL = 1000;

const SHARE_DOWNLOAD = "download";
const SHARE_INSTAGRAM_STORY = "instagram_story";
const SHARE_INSTAGRAM_FEED = "instagram_feed";

export default class CollagePage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isOwner: false,
      photo: null,
      collage: null,
      persons: [],
    };

    this.fetchCollage = this.fetchCollage.bind(this);
    this.handleCollage = this.handleCollage.bind(this);
    this.handleShareClick = this.handleShareClick.bind(this);
    this.shareInstagram = this.shareInstagram.bind(this);
    this.handleTryAgainButtonClick = this.handleTryAgainButtonClick.bind(this);
    this.handleFileSelected = this.handleFileSelected.bind(this);
    this.handleRandomPhoto = this.handleRandomPhoto.bind(this);
  }

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.collage) {
      this.handleCollage(this.props.location.state.collage);
    } else {
      this.fetchCollage();
    }
  }

  fetchCollage() {
    api.fetchCollage(this.props.match.params.hash).then((res) => {
      this.setState({
        photo: res.photo,
        persons: res.persons,
        isOwner: res.is_owner,
      }, () => this.handleCollage(res.collage));
    }).catch((err) => {
      console.error(err);
      this.props.history.replace(routes.ERROR);
    });
  }

  handleCollage(collage) {
    if (collage.status === COLLAGE_STATUS_FAILED) {
      logEvent(userEvents.COLLAGE_FAILED);
      this.props.history.replace(routes.ERROR);
    } else if (collage.status === COLLAGE_STATUS_PROCESSED) {
      logEvent(userEvents.COLLAGE_PROCESSED);
      this.setState({isLoading: false, collage}, this.context.hideLoader);
    } else {
      this.setState({isLoading: true, collage}, () => {
        setTimeout(this.fetchCollage, FETCH_INTERVAL);
      });
    }
  }

  handleShareClick(provider) {
    logEvent(userEvents.SHARE, {
      collage_id: this.state.collage.id,
      provider,
      persons: this.state.persons.length,
      user_position_column: this.state.collage.user_position_column,
      user_position_row: this.state.collage.user_position_row,
    });

    if (provider === SHARE_DOWNLOAD) {
      if (window.clientConfig.isWebview) {
        webviewUtils.webviewShareDownload(this.state.collage.file.url);
      } else {
        window.location.href = this.state.collage.download_url;
      }

      return;
    }

    const usernames = this.state.persons
        .map((person) => person.ig_username)
        .sort(() => 0.5 - Math.random())
        .slice(0, 7);

    this.context.pushModal(<ShareModal
        key="CollagePage-ShareModal"
        usernames={usernames}
        onCopyClick={(modal) => {
          logEvent(userEvents.SHARE_POPUP_COPY, {collage_id: this.state.collage.id, provider});
          copyTextWithInvisibleElement(usernames.map((username) => `@${username}`).join(" "));
          setTimeout(() => {
            modal.dismiss();
            this.shareInstagram(provider);
          }, 100);
        }}
    />);
  }

  shareInstagram(provider) {
    if (provider === SHARE_INSTAGRAM_STORY) {
      webviewUtils.webviewShareInstagramStory(window.location.href, this.state.collage.file.url);
    } else {
      webviewUtils.webviewShareInstagramFeed(window.location.href, this.state.collage.file.url);
    }
  }

  handleTryAgainButtonClick() {
    this.props.history.push(routes.CREATE, {
      photo: this.state.photo,
      selectedPersonsIds: this.state.persons.map((person) => person.id)
    });
  }

  handleFileSelected(file, startegy) {
    this.context.showLoader(true);

    const pushState = {file};
    if (startegy === "attach") {
      pushState.collageHash = this.state.collage.hash;
    } else if (startegy === "new") {
      pushState.parentCollageHash = this.state.collage.hash;
    }

    this.props.history.push(routes.CHOOSE, pushState);
  }

  handleRandomPhoto(startegy) {
    this.context.showLoader(true);

    api.fetchRandomPhoto(this.state.collage.id)
      .then((res) => {
        const pushState = {
          photo: res.photo
        };

        if (startegy === "attach") {
          pushState.collageHash = this.state.collage.hash;
        } else if (startegy === "new") {
          pushState.parentCollageHash = this.state.collage.hash;
        }

        this.props.history.push(routes.CREATE, pushState);
      })
      .catch((err) => {
        console.error(err);
        this.props.history.replace(routes.ERROR);
      });
  }

  render() {
    if (this.state.isLoading) {
      return null;
    }

    const isDesktop = window.screen.width > 768;

    return <div className="collage-page">
      <div className="container">
        <HomeButton page="collage" transitionStartegy="push" />

        <h2 dangerouslySetInnerHTML={{__html: i18n.t("collage_title")}} />
        <p>{i18n.t("collage_text")}</p>

        <div>
          <img src={this.state.collage.file.url} alt="Collage" />
        </div>

        <div className="share-container">
          <button
              hidden={!this.context.installedApps.instagram || window.clientConfig.isWeb}
              onClick={() => this.handleShareClick(SHARE_INSTAGRAM_FEED)}>
            <SvgInstagramIcon />
          </button>

          <button
              hidden={!this.context.installedApps.instagram || window.clientConfig.isWeb || window.clientConfig.isWebviewIOS}
              onClick={() => this.handleShareClick(SHARE_INSTAGRAM_STORY)}>
            <SvgInstaStoriesIcon />
          </button>

          <button
            hidden={isDesktop}
            onClick={() => this.handleShareClick(SHARE_DOWNLOAD)}>
            <SvgDownloadIcon />
          </button>

          <button
            className="desktop-download"
            children={i18n.t("collage_button_download")}
            hidden={window.clientConfig.isWebview || !isDesktop}
            onClick={() => this.handleShareClick(SHARE_DOWNLOAD)} />

        </div>

        <div className="collage-buttons-container">

          <FileChooseButton
            children={"Attach another photo"}
            onFileSelected={(file) => this.handleFileSelected(file, "attach")} />

          <button
            children={"Attach random photo"}
            hidden={!window.appConfig.isDebug}
            onClick={() => this.handleRandomPhoto("attach")} />

          <FileChooseButton
            children={"Upload photo to new"}
            onFileSelected={(file) => this.handleFileSelected(file, "new")} />

          <button
            children={"Random photo to new"}
            hidden={!window.appConfig.isDebug}
            onClick={() => this.handleRandomPhoto("new")} />

          <button
            children="New collage with same photo and celebrities"
            hidden={!window.appConfig.isDebug}
            onClick={this.handleTryAgainButtonClick} />

        </div>

      </div>
    </div>;
  }
}

CollagePage.contextType = AppContext;

class ShareModal extends Modal {

  renderModal() {
    return <div className="modal-container">
      <div className="modal">
        <h2 children={i18n.t("modal_title")} />
        <p children={i18n.t("modal_text")} />
        <ul>{this.props.usernames.map((username) => <li key={username}>@{username}</li>)}</ul>
        <button onClick={() => this.props.onCopyClick(this)} children={i18n.t("modal_button")} />
        <p className="text-motive" dangerouslySetInnerHTML={{__html: i18n.t("modal_postscript")}} />
      </div>
    </div>;
  }
}


function SvgDownloadIcon() {
  return <svg viewBox="0 0 120 120">
    <g fill="none" fillRule="evenodd">
      <g>
        <g transform="translate(-771 -1898) rotate(-90 1394.5 623.5)">
          <circle cx="60" cy="60" r="60" fill="#F7BA00"/>
          <path fill="#FFF" stroke="#FFF" strokeWidth="2" d="M37.222 57.188l13.442-13.61c1.167-1.182 1.172-3.094.01-4.27l.319.322c-1.162-1.176-3.045-1.178-4.215.007l-18 18.226c-1.166 1.18-1.17 3.09 0 4.274l18 18.226c1.166 1.18 3.052 1.184 4.215.007l-.319.323c1.162-1.177 1.154-3.092-.01-4.271l-13.442-13.61h53.114c1.656 0 2.997-1.347 2.997-2.993v.362c0-1.653-1.345-2.993-2.997-2.993H37.222z"/>
        </g>
      </g>
    </g>
  </svg>;
}

function SvgInstaStoriesIcon() {
  return <svg viewBox="0 0 120 120">
    <defs>
      <linearGradient id="rpfrd8k4qa" x1="16.667%" x2="83.333%" y1="85.417%" y2="14.583%">
        <stop offset="0%" stopColor="#FFC108"/>
        <stop offset="51.042%" stopColor="#F54636"/>
        <stop offset="100%" stopColor="#9E28AF"/>
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g fill="url(#rpfrd8k4qa)" fillRule="nonzero" transform="translate(-351 -1898)">
        <g>
          <path d="M118.287 59.993c0 32.218-26.073 58.286-58.285 58.286-32.218 0-58.286-26.073-58.286-58.286 0-2.515 2.039-4.553 4.554-4.553 2.514 0 4.553 2.038 4.553 4.553 0 27.183 21.999 49.179 49.179 49.179 27.183 0 49.178-21.999 49.178-49.179 0-27.183-21.999-49.178-49.178-49.178-2.515 0-4.554-2.039-4.554-4.554s2.039-4.553 4.554-4.553c32.217 0 58.285 26.073 58.285 58.285zm-80.05-44.215l5.89-2.44c2.323-.962 3.426-3.626 2.464-5.95-.963-2.323-3.626-3.427-5.95-2.464l-5.89 2.44c-2.323.963-3.426 3.627-2.464 5.95.96 2.316 3.619 3.429 5.95 2.464zM22.973 27.473l4.508-4.508c1.779-1.778 1.779-4.662 0-6.44-1.778-1.778-4.662-1.778-6.44 0l-4.507 4.508c-1.779 1.778-1.779 4.662 0 6.44 1.777 1.778 4.661 1.778 6.44 0zM7.397 46.583c2.328.964 4.99-.146 5.95-2.464l2.44-5.89c.962-2.324-.141-4.987-2.465-5.95-2.324-.963-4.987.14-5.95 2.464l-2.44 5.89c-.962 2.324.142 4.987 2.465 5.95zm52.605-9.358c-2.515 0-4.554 2.04-4.554 4.554v13.66h-13.66c-2.516 0-4.554 2.04-4.554 4.554 0 2.515 2.038 4.554 4.553 4.554h13.661v13.66c0 2.516 2.039 4.554 4.554 4.554s4.553-2.038 4.553-4.554v-13.66h13.661c2.515 0 4.554-2.039 4.554-4.554s-2.04-4.553-4.554-4.553h-13.66V41.779c0-2.515-2.04-4.554-4.554-4.554z" transform="translate(351 1898)"/>
        </g>
      </g>
    </g>
  </svg>;
}

function SvgInstagramIcon() {
  return<svg viewBox="0 0 120 120">
    <defs>
      <linearGradient id="b8bo1jh5sb" x1="6.731%" x2="93.591%" y1="93.485%" y2="6.619%">
        <stop offset="0%" stopColor="#FD5"/>
        <stop offset="50%" stopColor="#FF543E"/>
        <stop offset="100%" stopColor="#C837AB"/>
      </linearGradient>
      <circle id="43cz0f1gfa" cx="60" cy="60" r="60"/>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g transform="translate(-561 -1898) translate(561 1898)">
            <mask id="jf6fku2ycc" fill="#fff">
              <use xlinkHref="#43cz0f1gfa"/>
            </mask>
            <use fill="#D8D8D8" xlinkHref="#43cz0f1gfa"/>
            <path fill="url(#b8bo1jh5sb)" fillRule="nonzero" d="M8 8.428C-1.375 18.166.544 28.51.544 59.936c0 26.097-4.554 52.26 19.277 58.418 7.441 1.914 73.375 1.914 80.806-.01 9.922-2.56 17.995-10.608 19.098-24.64.154-1.96.154-65.542-.005-67.54C118.547 11.217 109.346 2.602 97.222.857 94.443.455 93.887.336 79.63.311 29.062.336 17.977-1.916 8 8.428z" mask="url(#jf6fku2ycc)"/>
          </g>
          <path fill="#FFF" fillRule="nonzero" d="M60 24.035c-14.745 0-28.747-1.311-34.095 12.405-2.21 5.664-1.889 13.021-1.889 23.555 0 9.244-.296 17.932 1.889 23.552 5.336 13.724 19.452 12.41 34.088 12.41 14.12 0 28.679 1.468 34.092-12.41 2.213-5.721 1.888-12.969 1.888-23.552 0-14.048.776-23.117-6.042-29.926-6.904-6.899-16.24-6.034-29.946-6.034H60zm-3.224 6.48c30.759-.049 34.673-3.465 32.513 44-.768 16.787-13.56 14.945-29.284 14.945-28.67 0-29.495-.82-29.495-29.48 0-28.994 2.274-29.449 26.266-29.473v.008zm22.433 5.97c-2.383 0-4.316 1.93-4.316 4.313 0 2.382 1.933 4.313 4.316 4.313 2.384 0 4.317-1.931 4.317-4.313 0-2.382-1.933-4.314-4.317-4.314zm-19.208 5.043c-10.205 0-18.478 8.27-18.478 18.467 0 10.198 8.273 18.464 18.478 18.464 10.205 0 18.473-8.266 18.473-18.464 0-10.197-8.268-18.467-18.473-18.467zm0 6.48c15.858 0 15.878 23.974 0 23.974-15.854 0-15.879-23.973 0-23.973z" transform="translate(-561 -1898) translate(561 1898)"/>
        </g>
      </g>
    </g>
  </svg>;
}
