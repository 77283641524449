import React from 'react';
import routes from "../routes";
import * as api from "../utils/api";
import AppContext from "../contexts/AppContext";
import i18n from "../i18n";
import HomeButton from "../components/HomeButton";
import {logEvent, userEvents} from "../utils/log";
import {generatePath} from "react-router";

const FETCH_INTERVAL = 1000;

const PHOTOLAB_STATUS_FAILED = -1;
const PHOTOLAB_STATUS_PROCESSED = 2;

export default class ChoosePage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      collageHash: null, // коллаж в который добавляем фото
      parentCollageHash: null, // коллаж в который добавляем фото, но как новый коллаж
    };

    this.createPhoto = this.createPhoto.bind(this);
    this.fetchPhoto = this.fetchPhoto.bind(this);
    this.handlePhotoStatus = this.handlePhotoStatus.bind(this);
    this.handleTemplateSelect = this.handleTemplateSelect.bind(this);

    this.fetchTimer = null;
  }

  componentDidMount() {
    if (this.props.location.state) {
      this.createPhoto(this.props.location.state);
    } else {
      this.props.history.replace(routes.INDEX);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.fetchTimer);
  }

  createPhoto({file, collageHash, parentCollageHash}) {
    this.setState({collageHash, parentCollageHash});

    api.createPhoto(file, {preview: true})
      .then((res) => {
        logEvent(userEvents.PHOTO_UPLOADED);
        this.handlePhotoStatus(res);
      })
      .catch((err) => {
        console.error(err);
        logEvent(userEvents.PHOTO_UPLOAD_FAILED);
        this.props.history.replace(routes.ERROR);
      });
  }

  fetchPhoto() {
    api.fetchPhoto(this.state.photo.id)
      .then(this.handlePhotoStatus)
      .catch((err) => {
        console.error(err);
        this.props.history.replace(routes.ERROR);
      });
  }

  handlePhotoStatus(res) {
    const nextState = {
      isLoading: true,
      photo: res.photo,
    };

    this.context.setLoaderImage(res.photo.file.url);

    if (nextState.photo.preview_task.status === PHOTOLAB_STATUS_FAILED) {
      logEvent(userEvents.PROCESSING_FAILED, {type: "preview"});
      this.props.history.replace({
        pathname: routes.ERROR,
        state: {message: nextState.photo.preview_task.error.message}
      });
      return;
    } else if (nextState.photo.preview_task.status === PHOTOLAB_STATUS_PROCESSED) {
      logEvent(userEvents.PROCESSING_PROCESSED, {type: "preview"});
      nextState.isLoading = false;
    } else {
      this.fetchTimer = setTimeout(this.fetchPhoto, FETCH_INTERVAL);
    }

    this.setState(nextState, () => {
      if (this.state.isLoading === false) {
        this.context.hideLoader();
      }
    });
  }

  handleTemplateSelect(e, templateId) {
    logEvent(userEvents.TEMPLATE_SELECT, {
      template_id: templateId
    });

    this.setState({
      isLoading: true,
    }, this.context.showLoader);

    api.submitPhotoTemplate(this.state.photo.id, templateId)
      .then((res) => {
        this.props.history.replace(routes.CREATE, {
          photo: this.state.photo,
          collageHash: this.state.collageHash,
          parentCollageHash: this.state.parentCollageHash,
          skip: true
        });
      })
      .catch((err) => {
        console.error(err);
        this.props.history.replace(routes.ERROR);
      });
  }

  render() {
    if (this.state.isLoading) {
      return null;
    }

    const templates = Object.keys(this.state.photo.preview_task.response.results);

    return <main className="choose-page">
      <div className="container">
        <HomeButton page="choose" />

        <h2>{i18n.t("choose_title")}</h2>
        <p>{i18n.t("choose_text")}</p>
      </div>
      <div className="choose-items">
        {templates.map((templateId) => <div
          key={templateId}
          className="choose-item-container"
          onClick={(e) => this.handleTemplateSelect(e, templateId)}>
          <div className="choose-item">
            <img src={this.state.photo.preview_task.response.results[templateId]} alt={templateId} />
          </div>
        </div>)}
      </div>
    </main>
  }
}

ChoosePage.contextType = AppContext;